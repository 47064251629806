import {Pages}                   from "./Pages";
import {legacy}                  from "../../legacy";
import ReactDOM                  from "react-dom";
import {PersonDependentDropList} from "../components/PersonDependentDropList";
import React                     from "react";
import {PersonDependentElements} from "../enums/PersonDependentElements";
import {Comments}                from "../components/Comments";

export class Wishes extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        this.initPageComponents();

        $(".program-detail-link").click(function (event) {
            let $element = $(event.target),
                programId = $element.attr("data-program-id");
            legacy.getCourseDetail(programId);
        });

    }

    initPageComponents() {

        ReactDOM.render(
            <PersonDependentDropList
                entity={PersonDependentElements.WISHES}/>,
            document.getElementById('wishes-search-form'),
        );

        let commentsCells = document.querySelectorAll("[data-comments]");
        for (let i = 0; i < commentsCells.length; i++) {
            let element = commentsCells[i];
            ReactDOM.render(
                <Comments
                    entityType={element.dataset.entityType}
                    entityId={element.dataset.entityId}
                />,
                element,
            );
        }

    }
}
